$(document).on('click','.js-collapse',function() {
  $('.js-collapse').removeClass('active');
  $('.collapseContent').slideUp();
  $(this).toggleClass('active');
  $(this).find('.collapseContent').slideToggle('active');
})


let options = {
  startAngle: -1.55,
  size: 150,
  value: 0.60,
  fill: {color: '#FEBF01'}
}



$(window).on("scroll", function () {
  let sticky = $(".js-header");
  let scrollTop = $(window).scrollTop();
  if (scrollTop >= 10) {
    $(sticky).addClass("sticky");
    $('.bLogo .white').addClass('d-none');
    $('.bLogo .color').removeClass('d-none');
    $('.border-left').addClass('secondary-position');
    $('.border-right').addClass('secondary-position');
    $('.headerItem').addClass('animate__backInUp')
  } else {
    $(sticky).removeClass("sticky");
    $('.bLogo .white').removeClass('d-none');
    $('.bLogo .color').addClass('d-none');
    $('.border-left').removeClass('secondary-position');
    $('.border-right').removeClass('secondary-position');
  }
  if ($('.circularContent').is(':visible')) {
    console.log('visible');
  }
  if (window.innerWidth <= 991) {
    $('.headerItem').find('.bNaw').css('display','none');
  } 

});

$(window).on('load',function() {
  $('.bannerItem').addClass('load');
  var cookieAgreement = localStorage.getItem('cookieAgreement');
  if(cookieAgreement || cookieAgreement == 'true') {
    $('.kvkk').addClass('d-none');
  }
  else {
    $('.kvkk').removeClass('d-none');
  }
})

let owl = $(".js-modal-slider");
$(".js-modal-slider").owlCarousel({
  loop: false,
  items: 2,
  dots: false,
  margin: 8,
  nav: false,
  autoWidth: true,
  loop:true,
  responsive: {
    0: {
      items: 1,

    },
    991: {
      items: 1,
    },
    992: {
      items: 2,
    }
  }

});
$(document).on("click", ".js-prev-navigation", function (e) {
  owl.trigger("prev.owl.carousel");
  e.preventDefault()
});
$(document).on("click", ".js-next-navigation", function (e) {
  owl.trigger("next.owl.carousel");
  e.preventDefault()
});

$(document).on('click','.js-modal-open',function(e) {
  $('.js-modal').addClass('active');
  $('html,body').addClass('o-hidden');
  e.preventDefault();
})
$(document).on('click','.js-modal-close',function() {
  $('.js-modal').removeClass('active');
  $('html,body').removeClass('o-hidden');
})

$(document).on('click','.js-contact-modal-open',function(e) {
  $('.js-modal-contact').addClass('active');
  $('html,body').addClass('o-hidden');
  e.preventDefault();
})
$(document).on('click','.js-contact-modal-close',function(e) {
  $('.js-modal-contact').removeClass('active');
  $('html,body').removeClass('o-hidden');
  e.preventDefault();
})



function animation() {
  var element = document.querySelector('.logo-animation');
	var position = element?.getBoundingClientRect().top;

	
	if(position >= 0 && position.bottom <= window.innerHeight) {
		console.log('Element is fully visible in screen');
    $('.logo-animation-item img').show();
	}
}

var state = true;

function charts() {
  var element = document.querySelector('.circularContent');
	var position = element?.getBoundingClientRect();
  
  if (!state) {
    return false;
  }
	
	if(position.top >= 0 && position.bottom <= window.innerHeight && state == true) {
    chartsAnimate();
    state = false;
	}
}


window.addEventListener('scroll', function() {
  animation();
  charts();
});


function chartsAnimate() {
  $(".circle .bar").circleProgress(options).on('circle-animation-progress',
function(event, progress, stepValue){
  $(this).parent().find("span").text(String("%" + stepValue.toFixed(2).substr(2)));
});
$(".one .bar").circleProgress({
  value: 0.60,
  fill: {color: '#FEBF01'},
  size: 150,
});
$(".two .bar").circleProgress({
  value: 0.50,
  fill: {color: '#FF0038'},
  size: 150
});      
$(".three .bar").circleProgress({
  value: 0.40,
  fill: {color: '#D80000'},
  size: 150
});
$(".four .bar").circleProgress({
  value: 0.80,
  fill: {color: '#8AE101'},
  size: 150
});
$(".five .bar").circleProgress({
  value: 0.70,
  fill: {color: '#F39B02'},
  size: 150
});
$(".six .bar").circleProgress({
  value: 0.55,
  fill: {color: '#FF0038'},
  size: 150
});
}

$(document).on('click','.mobile-collapse-button',function(e) {
   $(this).parent('.headerItem').find('.bNaw').slideToggle();
   e.preventDefault();
})


$(document).on('click','.js-close',function() {
  $('.kvkk').addClass('d-none');
  localStorage.setItem('cookieAgreement',true);
})